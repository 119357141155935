<template>
    <div class="flex justify-between px-2 py-4">
        <el-tooltip class="item" effect="dark" content="Unmatched" placement="top">
            <div @click="selectType(0)" class="flex items-center px-2 border-2 border-white rounded-full" :class="{ 'border-green-500': selectedType === 0 }">
                <span class="flex h-4 w-4 mr-1 rounded-full bg-gray-300" />
                <p>
                    {{ getCount(0) }}
                </p>
            </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Negativt belopp hittat" placement="top">
            <div @click="selectType(20)" class="flex items-center px-2 border-2 border-white rounded-full" :class="{ 'border-green-500': selectedType === 20 }">
                <span class="flex h-4 w-4 mr-1 rounded-full bg-red-500" />
                <p>
                    {{ getCount(20) }}
                </p>
            </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="OCR missing, invoice number matched" placement="top">
            <div @click="selectType(40)" class="flex items-center px-2 border-2 border-white rounded-full" :class="{ 'border-green-500': selectedType === 40 }">
                <span class="flex h-4 w-4 mr-1 rounded-full bg-yellow-500" />
                <p>
                    {{ getCount(40) }}
                </p>
            </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="OCR matched, amount < rest" placement="top">
            <div @click="selectType(60)" class="flex items-center px-2 border-2 border-white rounded-full" :class="{ 'border-green-500': selectedType === 60 }">
                <div class="flex mr-1">
                    <p class="flex h-4 w-2 rounded-l-full bg-yellow-500" />
                    <p class="flex h-4 w-2 rounded-r-full bg-green-500" />
                </div>
                <p>
                    {{ getCount(60) }}
                </p>
            </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="OCR matched, amount > rest" placement="top">
            <div @click="selectType(80)" class="flex items-center px-2 border-2 border-white rounded-full" :class="{ 'border-green-500': selectedType === 80 }">
                <div class="flex mr-1">
                    <p class="flex h-4 w-2 rounded-l-full bg-green-500" />
                    <p class="flex h-4 w-2 rounded-r-full bg-yellow-500" />
                </div>
                <p>
                    {{ getCount(80) }}
                </p>
            </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Skuld till Bolageriet finns" placement="top">
            <div class="flex items-center px-2 border-2 border-white rounded-full">
                <span class="flex h-4 w-4 mr-1 rounded-full bg-black" />
                <p>
                    {{ getDebtCount() }}
                </p>
            </div>
        </el-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        paymentsData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedType: null,
        };
    },

    methods: {
        getCount(paymentMatchLevel) {
            return this.paymentsData.filter(item => item.matchLevel === paymentMatchLevel).length;
        },

        getDebtCount() {
            return this.paymentsData.filter(item => item.hasDebt).length;
        },

        selectType() {
            return;
        },
    },
};
</script>
